/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import 'algolia/algolia';
@import '~libs/nxtg-shared/src/assets/scss/mixins';

.mat-bottom-sheet-container {
    max-height: 100vh !important;
    padding: 0!important;
}

.filepond--credits {
    display: none;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.82) !important;
}

.cdk-overlay-backdrop.blur {
    backdrop-filter: blur(4px);
}

.cdk-global-overlay-wrapper.responsive {
    @include breakpoint(lt-sm) {
        align-items: start !important;
    }
}

.cdk-overlay-pane.responsive {
    .mat-dialog-container {
        padding: 0px !important;
    }

    @include breakpoint(lt-sm) {
        .mat-dialog-container {
            >* {
                height: 100% !important;
                @supports (-webkit-touch-callout: none) {
                    height: -webkit-fill-available !important;
                }
                display: flex;
                overflow: hidden;
            }

            min-height: calc(100vh - env(safe-area-inset-bottom)) !important;
            height: calc(100vh - env(safe-area-inset-bottom)) !important;

            @supports (-webkit-touch-callout: none) {
                min-height: -webkit-fill-available !important;
                height: -webkit-fill-available !important;
            }
        }

        min-width: 100%;
        max-width: 100% !important;
        min-height: calc(100vh - env(safe-area-inset-bottom));

        @supports (-webkit-touch-callout: none) {
            min-height: -webkit-fill-available !important;
        }
    }
}

.cdk-overlay-pane.fullscreen {
    max-width: 100% !important;
    min-height: calc(100vh - env(safe-area-inset-bottom)) !important;

    @supports (-webkit-touch-callout: none) {
        min-height: -webkit-fill-available !important;
    }

    @include breakpoint(md) {
        min-height: 0 !important;
    }
}

.cdk-overlay-pane.transparent {
    .mat-dialog-container {
        background: transparent !important;
    }
}

.google-maps-fixed {
    .pac-container {
        position: fixed !important;
        z-index: 1001;
    }
}

.pac-container {
    z-index: 1001;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ SimpleMDE/CodeMirror styles
/* ----------------------------------------------------------------------------------------------------- */
body.dark {
    .CodeMirror {
        @apply bg-gray-800 #{!important};
        @apply text-white #{!important};
    }

    .editor-preview,
    .editor-preview-side {
        @apply bg-gray-800 #{!important};
        @apply text-white #{!important};
    }

    .editor-toolbar a {
        display: inline-block;
        text-align: center;
        text-decoration: none !important;
        width: 30px;
        height: 30px;
        margin: 0;
        border: 1px solid transparent;
        border-radius: 3px;
        cursor: pointer;
        @apply text-white #{!important};
    }

    .editor-toolbar.disabled-for-preview {
        a {
            &:not(.no-disable) {
                @apply bg-gray-800 #{!important};
            }
        }
    }

    .editor-toolbar a.active,
    .editor-toolbar a:hover {
        @apply bg-gray-900 #{!important};
        border-color: #95a5a6;
    }
}

video.no-controls {
    &::-webkit-media-controls {
        display: none !important;
    }

    &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
    }

    &::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
    }

    &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }

    &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
    }
}
